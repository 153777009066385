
// Adds an upwards triangle onto the top of the element. Its size is determined by $arrow-width and $arrow-height.
// The $left parameter specifies the distance of the center of the arrow from the left border of the element.
@mixin box-arrow-top($color-background, $color-background-hover, $color-border, $arrow-width, $arrow-height, $border-width, $left) {

  position: relative;

  &:after, &:before {
    bottom: 100%;
    left: $left;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  $half-arrow-width: $arrow-width / 2;
  $outer-half-arrow-width: ($half-arrow-width / $arrow-height) * $border-width + $half-arrow-width;

  // The before element represents the inner triangle (the filling of the triangle)
  &:after {
    margin-left: -$half-arrow-width;
    border-width: $half-arrow-width;
    border-bottom-width: $arrow-height;
    border-bottom-color: $color-background;
  }

  &:hover:after {
    border-bottom-color: $color-background-hover;
  }

  // The after element represents the outer triangle (the border of the triangle)
  &:before {
    margin-left: -$outer-half-arrow-width;
    border-width: $outer-half-arrow-width;
    border-bottom-width: $arrow-height + $border-width;
    border-bottom-color: $color-border;
  }
}