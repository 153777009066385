/*
    based on:
    http://red-team-design.com/css3-breadcrumbs/
*/

@import "colors.scss";
@import "box-arrow.scss";

$breadcrumbs-height: 29px;
$toolbar-element-height: 32px;

.part-title-element {
    height: $toolbar-element-height;
    display: inline-block;
}

.arrow-button {
    padding: 6px 0px;
    vertical-align: top;
    margin: 0px 6px;
}

.breadcrumbs {
    @extend .part-title-element;

    vertical-align: middle;
    position: relative;
    cursor: pointer;
    background-color: $white;

    .bc-controls {
        @extend .part-title-element;

        margin: 0;
        padding-left: 5px;
        border:none;
        font-size: 18px;
        height: 32px;
        vertical-align: bottom;
        overflow: hidden;
        white-space: nowrap;

        .bc-title {
            line-height: 32px;
            font-weight: bold;
            white-space: nowrap;
        }

        .arrow-button {
            @extend .part-title-element;
        }
    }

    .bc-controls > * {
        height: 32px;
        display: inline-block;
    }

    .breadcrumbs-menu {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0 -1px 0 0;

        position: absolute;
        top: auto;
        left: 0;

        border-top: 1px solid $light;
        border-right: 1px solid $light;

        background-color: $white;

        z-index: 999; // show behind the sidebar

        > div {
            white-space: nowrap;
        }

        a {
            @include box-arrow-top($white, $light, $light, 20px, 10px, 1px, 19px);

            padding-left: 40px;
            display: block;
        }

        a,
        a:hover,
        a:active,
        a:visited,
        a:focus {
            color: $red;
        }

    }
}
